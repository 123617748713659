/**
 * Grid
 * 
 * .full-container
 * +-------------------------------------------------+
 * |                                                 |
 * |                                                 |
 * |                                                 |
 * +-------------------------------------------------+
 * |                                                 |
 * |   .container                                    |
 * |   +---+---------------------------------+---+   |
 * |   |///|.flex-row                        |///|   |
 * |   |/+-------------------------------------+/|   |
 * |   |/|.flex-col         .flex-col        |\|/|   |
 * |   |/+------------------+------------------+/|   |
 * |   |/|/|.bg-gray      |/|/|              |/|/|   |
 * |   |/|/+--------------+/|/|              |/|/|   |
 * |   |/|/|SSSSSSSSSSSSSS|/|/|              |/|/|   |
 * |   |/|/|S            S|/|/|              |/|/|   |
 * |   |/|/|SSSSSSSSSSSSSS|/|/|              |/|/|   |
 * |   +-+-+--------------+-+------------------+-+   |
 * |                                                 |
 * |   .container                                    |
 * |   +-----------------------------------------+   |
 * |   |///|.bg-gray (con margine negativo)  |///|   |
 * |   |/+-------------------------------------+/|   |
 * |   |/|SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS|/|   |
 * |   |/|SS                                 SS|/|   |
 * |   |/|SS                                 SS|/|   |
 * |   |/|SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS|/|   |
 * |   |/+-------------------------------------+/|   |
 * |   |///|.bg-gray                         |///|   |
 * |   |///+---------------------------------+///|   |
 * |   |///|SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS|///|   |
 * |   |///|SS                             SS|///|   |
 * |   |///|SS                             SS|///|   |
 * |   |///|SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS|///|   |
 * |   |///+---------------------------------+///|   |
 * |   |///|                                 |///|   |
 * |   +-----------------------------------------+   |
 * |                                                 |
 * +-------------------------------------------------+
 * 
 */

// Il padding delle colonne compensa il margine negativo delle righe
%column-padding {
  padding-left: $column-padding;
  padding-right: $column-padding;
}
%row-margin {
  margin-left: -$column-padding;
  margin-right: -$column-padding;
}

// Full container
.full-container {
	width: 100%;
}

// Container
.container {
	width: 100%;
	padding: 0 $container-padding;
	margin-left: auto;
	margin-right: auto;
	@include small-breakpoint {
		max-width: $small;
	}
	@include medium-breakpoint {
		max-width: $medium;
	}
	@include large-breakpoint {
		max-width: $large;
	}
}

.row {
  @extend %row-margin;
}
.col {
  @extend %column-padding;
}

.flex-row {
  @extend %row-margin;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-small,
.flex-large {
  @extend %column-padding;
  flex-basis: 100%;
  margin-bottom: $margin-bottom;
}

.flex-small, .flex-large {
  // NodeTypes Columns
  &.four-columns-column {
	flex-basis: 50%;
  }
}

/* Small screen breakpoint */

@include small-breakpoint {
  .flex-small {
    margin-bottom: 0;
    // NodeTypes Columns
    &.four-columns-column {
      flex-basis: 25%;
    }
    &.three-columns-column {
      flex-basis: 33.33%;
    }
    &.two-columns-column {
      flex-basis: 50%;
    }
  }
}

/* Large screen breakpoint */

@include large-breakpoint {
  .flex-large {
    margin-bottom: 50px;
    // NodeTypes Columns
    &.four-columns-column {
      flex-basis: 25%;
    }
    &.three-columns-column {
      flex-basis: 33.33%;
    }
    &.two-columns-column {
      flex-basis: 50%;
    }
  }
}
