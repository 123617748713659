// Stili custom

* {
    font-family: "Maven Pro" !important;
    scroll-behavior: smooth !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300;
}
.big-text {
    font-size: 50px;
}
p,
span {
    line-height: 1.2;
}
main {
    overflow: hidden;
}

.bottone-rosso,
.bottone-bianco {
    @extend .button;
    border-radius: 0;
    padding: 20px 40px;
    transition: all 0.2s;
    font-weight: 100;
    font-size: 12px;
    border: 0 !important;
}
.bottone-rosso {
    color: white;
    background-color: $rosso;
    border-color: $rosso;
    position: relative;
    padding-left: 60px;
    z-index: 120;
    &::before {
        transition: all 0.2s;
        content: "";
        position: absolute;
        width: 20px;
        height: 2px;
        bottom: 50%;
        left: 22px;
        z-index: 1;
        background-color: white;
    }
}
.bottone-bianco {
    color: black;
    background-color: white;
    border-color: transparent;
    position: relative;
    padding-left: 60px;
    z-index: 120;
    &::before {
        transition: all 0.2s;
        content: "";
        position: absolute;
        width: 20px;
        height: 2px;
        bottom: 50%;
        left: 22px;
        z-index: 1;
        background-color: black;
    }
}

.bottone-rosso:hover {
    color: $rosso !important;
    background-color: white !important;
    border-color: $rosso !important;
    border: 0 !important;
    &::before {
        background-color: $rosso;
    }
}
.bottone-bianco:hover {
    color: white;
    background-color: $rosso;
    border: 0 !important;
    &::before {
        background-color: white;
    }
}

.rosso,
.rosso-tratto-ambedue,
.rosso-tratto-destra,
.rosso-tratto-sinistra {
    color: $rosso;
    position: relative;
}
.bianco-tratto-sinistra {
    color: white;
    position: relative;
}

.small-text {
    font-size: 14px;
}

.mtn-12 {
    margin-top: -120px;
}

.absolute {
    position: absolute !important;
    z-index: -10;
}

.box-quadrati {
    line-height: 22px;
    h3 {
        max-width: 250px;
    }
    .neos-contentcollection {
        z-index: 5;
        position: relative;
        .adam-column {
            overflow: hidden;
            position: relative;
            padding: 30px 20px;
            min-height: 240px;
            border: 1px solid transparent;
            -webkit-box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.19);
            box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.19);
            background-color: white;
            transition: all 0.3s;
            &:hover {
                h2,
                h3 {
                    color: white !important;
                }
                .bottone-rosso {
                    color: $rosso;
                    background-color: white;
                    &::before {
                        background-color: $rosso;
                    }
                }
                .bottone-bianco {
                    color: white;
                    background-color: $rosso;
                    &::before {
                        background-color: white;
                    }
                }
                color: white !important;
                background-color: $rosso;
                .flex-row.two-columns {
                    .adam-column {
                        .adam-image {
                            top: -53px;
                            height: 107px;
                        }
                    }
                }
            }
        }
        .flex-row.two-columns {
            margin-right: 20px;
            margin-top: -15px;
            .adam-column {
                -webkit-box-shadow: unset;
                box-shadow: unset;
                background-color: unset;
                transition: unset;
                .adam-image {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 50%;
                    height: 55px;
                    overflow: hidden;
                }
            }
        }
    }
}

.box-shadow {
    .neos-contentcollection {
        .adam-column {
            overflow: hidden;
            position: relative;
            min-height: 240px;
            padding: 32px;
            -webkit-box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.19);
            box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.19);
            transition: all 0.3s;
            &:hover {
                color: white !important;
                background-color: $rosso;
                .p{
                    color: white !important;
                }
                .background-bianco {
                    color: white !important;
                    background-color: $rosso;
                }
            }
        }
    }
}

.box-shadow-single {
    overflow: hidden;
    position: relative;
    min-height: 240px;
    padding: 32px;
    -webkit-box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.19);
    box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.19);
    transition: all 0.3s;
    &:hover {
        color: white !important;
        background-color: $rosso;
        .p{
            color: white !important;
        }
    }
}

.bianco-tratto-sinistra,
.rosso-tratto-destra {
    margin-left: 60px;
}

.rosso-tratto-ambedue:before,
.rosso-tratto-destra:before {
    content: "";
    position: absolute;
    width: 50px;
    height: 2px;
    top: 8px;
    left: -60px;
    z-index: 1;
    background-color: $rosso;
}
.bianco-tratto-sinistra:before {
    content: "";
    position: absolute;
    width: 50px;
    height: 2px;
    top: 8px;
    left: -60px;
    z-index: 1;
    background-color: white;
}

.rosso-tratto-ambedue:after,
.rosso-tratto-sinistra:after {
    content: "";
    position: absolute;
    width: 50px;
    height: 2px;
    top: 8px;
    right: -60px;
    z-index: 1;
    background-color: $rosso;
}

#contact,
#newsletter {
    input {
        margin-bottom: 10px;
    }
    color: white;
    label {
        margin-bottom: 2px;
        font-weight: 300 !important;
    }
    .form-fields {
        display: flex;
        flex: 0 0 50% !important;
        flex-wrap: wrap;
        .clearfix {
            margin: auto;
            margin-left: 0;
            &:nth-child(6) {
                display: flex;
                flex-direction: row-reverse;
                text-align: left;
                margin-top: 20px;
                margin-bottom: 20px;
                margin-left: 0px;
                label {
                    margin-right: 10px;
                }
            }
        }
    }
    .btn.btn-primary {
        background-color: $rosso;
        border-radius: 0;
        font-size: 16px;
        font-weight: 100;
        width: 170px;
    }
}
#contact {
    .form-fields {
        display: unset;
        flex: unset;
        flex-wrap: unset;
        .clearfix {
            &:nth-child(6) {
                display: flex;
                flex-direction: row-reverse;
                text-align: left;
                margin-top: 20px;
                margin-bottom: 20px;
                float: left;
                margin-left: 0;
                label {
                    margin-right: 10px;
                }
            }
        }
    }
}
#newsletter {
    .form-fields {
        .clearfix {
            &:nth-child(3) {
                display: flex;
                flex-direction: row-reverse;
                text-align: left;
                margin-top: 20px;
                margin-bottom: 20px;
                margin-left: 0px;
                label {
                    margin-right: 10px;
                }
            }
        }
    }
}
@media (min-width: $small) {
    .doppio-grigio {
        .background-alternate {
            background: linear-gradient(
                90deg,
                $grigio-chiaro 50%,
                $grigio-scuro 50%
            );
        }
    }
}
.immagine-cover {
    div {
        min-height: 330px;
    }
}
.immagine-cover {
    position: relative;
    overflow: hidden;
    .adam__text-wrapper__text {
        z-index: 3;
        h1 {
            margin-bottom: 0px;
        }
        .bottone-bianco {
            margin-top: 20px;
        }
        &::after {
            content: "";
            position: absolute;
            width: 360px;
            height: 360px;
            min-width: 100px;
            min-height: 100px;
            top: 15%;
            left: 10%;
            border-radius: 100%;
            right: 100px;
            z-index: -10;
            background-color: $rosso;
            content: "";
            vertical-align: middle;
        }
    }
}

.lato-rosso {
    position: relative;
    padding-left: 20px;
    &::after {
        content: "";
        position: absolute;
        width: 5px;
        height: 100%;
        bottom: 0px;
        left: 0px;
        z-index: 1;
        background-color: $rosso;
    }
}

@media (min-width: 800px) {
    .site-navbar > * {
        flex-grow: unset;
    }
}

@media (min-width: $large) {
    .immagine-cover {
        .adam__text-wrapper__text {
            &::after {
                width: 500px;
                height: 500px;
                top: 15%;
                left: 15%;
            }
        }
    }
}

@media (max-width: $large) {
    .mtn-12 {
        margin-top: -50px;
    }
}

@media (max-width: $large) {
    .box-quadrati {
        line-height: 22px;
        h3 {
            max-width: 170px;
        }
    }
}

@media (max-width: $small) {
    .immagine-cover {
        img {
            width: unset !important;
        }
    }
    .immagine-cover {
        position: relative;
        overflow: hidden;
        .adam__text-wrapper__text {
            z-index: 3;
            h1 {
                margin-bottom: 0px;
            }
            .bottone-bianco {
                margin-top: 20px;
            }
            &::after {
                position: absolute;
                width: 260px;
                height: 260px;
                min-width: 100px;
                min-height: 100px;
                top: 30%;
                left: 20%;
                border-radius: 100%;
                right: 100px;
                z-index: -10;
                background-color: #ed1c24;
                content: "";
                vertical-align: middle;
            }
        }
    }
}

.numeri-rossi {
    ol {
        display: flex;
        flex: 0 50%;
        flex-direction: column;
        line-height: 1.3;
        li {
            padding-right: 50px;
            font-size: 25px;
            &::marker {
                color: $rosso;
                font-size: 20px;
            }
        }
    }
}

.lista-rossa {
    ul {
        list-style-type: none;
        li {
            position: relative;
            margin-left: 20px;
            &::before {
                content: "";
                position: absolute;
                width: 15px;
                height: 2px;
                bottom: 10px;
                left: -20px;
                z-index: 1;
                background-color: $rosso !important;
            }
        }
    }
}

.secondary-menu {
    display: none !important;
}



.site-navbar {
    position: relative;
    padding: 20px 0px;
}

.pre-menu {
    display: flex;
    top: 0;
    position: absolute;
    right: 20px;
    z-index: 124120412;
    width: 50% !important;
    align-items: center;
    justify-content: flex-end;
    .immagine-navbar {
        width: 35px;
        margin-right: 10px;
        &:hover {
            cursor: pointer;
        }
    }
    .testo-navbar {
        width: 170px;
    }
    .background-rosso {
        background-color: $rosso;
        padding: 3px 10px;
        margin-right: 0;
        width: 170px;
        font-size: 14px;
        font-weight: 300;
    }
}
.menu-item--current {
    a {
        color: $rosso !important;
    }
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700 !important;
}

// .language-menu{
//     display: none !important;
// }

// .language-menu:first-child{
//     display: none !important;
// }

.language-menu{
    ul{
        li{
            &:last-child{
                display: none;
            }
        }
    }
}

.desktop-menu{
    .language-menu-main{
        display: none !important;
    }
}

.pre-menu{
    .language-menu.menu--level-0{
        height: 40px;
        background-color: $blu-scuro;
        color: white !important;
        .menu-item-link{
            color: white !important;
            padding: 10px 0px;
            font-size: .875rem;
        }
        .menu--level-1{
            background-color: $blu-scuro;
            left: unset;
            right: 0;
            font-size: .875rem;
            .menu-item-link{
                &:hover{
                    color: $rosso !important;
                }
            }
        }
    }
}


@media (max-width: $small) {
    .site-navbar ul:first-of-type {
        display: none;
    }
    .rosso-tratto-ambedue{
        &::after, &::before{
            display: none;
        }
    }
    .site-navbar {
        ul {
            text-align: right;
            li {
                text-align: right;
                a {
                    padding: 12px 0 0 0 !important;
                }
                &:last-child {
                    display: none;
                }
            }
        }
    }
    footer {
        .three-columns {
            .three-columns-column {
                flex-basis: 100% !important;
                #newsletter .form-fields{
                    flex-direction: column;
                }
                .three-columns {
                    .three-columns-column {
                        flex-basis: 30% !important;
                    }
                }
                ul{
                    padding-left: 0;
                }
            }
        }
    }
    .adam-image{
        h1{
            font-size: 26px;
            span, .big-text{
                font-size: 26px;
            }
        }
    }
}

.loghi-social {
    .four-columns {
        .four-columns-column {
            padding: 0 !important;
            flex-basis: 16%;
        }
    }
}

video.neos-plyr {
    width: 100%;
}

.news-pagination {
    padding-top: 20px;
}

.news-list {
    h3 {
        font-size: 20px;
    }
    h4 {
        font-size: 16px;
        padding: 10px 0px 20px 0px;
    }
    p.float-right {
        font-size: 14px;
    }
}

@media (max-width: $large) {
    .two-columns {
        .two-columns {
            .two-columns-column {
                flex-basis: 100% !important;
                margin-bottom: 20px;
            }
        }
    }
}
@media (max-width: $medium) {
    .desktop-menu{
        .language-menu-main{
            display: block !important;
        }
    }
    .mobile-menu{
        .menu-item{
            &:nth-last-child(3){
                margin-top: 12px;
                border-top: 1px solid #B5B5B5;
            }
        }
    }
    .box-quadrati {
        .neos-contentcollection {
            z-index: 5;
            position: relative;
            .three-columns {
                flex-direction: column-reverse;
                .three-columns-column {
                    flex-basis: 100%;
                    margin-bottom: 1rem;
                }
            }
            .flex-row.two-columns.twoThirdsLeft {
                flex-direction: column-reverse;
                .column-two {
                    width: 100% !important;
                }
                .adam-column {
                    -webkit-box-shadow: unset;
                    box-shadow: unset;
                    background-color: unset;
                    transition: unset;
                    .adam-image {
                        position: absolute;
                        right: 0;
                        top: 0;
                        max-width: 50px;
                        width: 13%;
                        height: 55px;
                        overflow: hidden;
                    }
                }
            }
        }
    }
}
@media (max-width: 769px) {
    .main-menu-toggler {
        display: inline-block !important;
        margin: auto 0 auto auto;
    }
    .site-navbar {
        > * {
            flex-grow: unset;
        }
        ul.menu-mobile {
            display: block !important ;
        }
    }
    footer {
        .three-columns {
            .three-columns-column {
                flex-basis: 28% !important;
            }
        }
    }
}

a {
    line-height: 1.2;
}

.background-primary .background-rosso,
.background-blu-scuro,
.background-blu-nero {
    color: white;
    ul {
        li {
            &::marker,
            &::before {
                background-color: white;
            }
        }
    }
}

.background-primary ul > li::marker,
.background-rosso ul > li::marker,
.background-blu-scuro ul > li::marker,
.background-blu-nero ul > li::marker {
    color: white;
}

@media (min-width: 768px) {
    .menu-item--level-0 > .menu-item-link {
        padding: 3rem 0 0 0;
    }
}

.news-pagination{
    margin: auto;
    padding-top: 40px;
    display: flex;
    align-items: baseline;
    justify-content: center;
    @media (max-width: 768px) {
        .next-button{
            margin-left: 10px;
        }
        .previous-button{
            margin-right: 10px;
        }
    }
    .next-button{
        margin-left: 20px;
    }
    .previous-button{
        margin-right: 20px;
    }
}

@media (max-width: 425px) {
    ol, ul{
        padding-left: 30px;
    }
    .sistema-mobile{
        padding: 0 !important;
    }
}