/**
 * Layout
 */

// HTML base
html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font: $font-style $font-variant $font-weight #{$font-size}/#{$line-height} $font-family;
	font-size: $font-size;
}

// Body base
body {
	color: $font-color;
	background: $background;
	font-size: $body-font-size;
}

p,
ol,
ul,
dl,
table {
	margin: 0 0 $margins 0;
	&:last-child {
		margin-bottom: 0;
	}
}

// Remove extra margin for nested lists
ul li ul {
	margin-bottom: 0;
}

ol li ol {
	margin-bottom: 0;
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0 0 $margins;
	font-weight: $heading-font-weight;
	font-family: $heading-font-family;
	line-height: $heading-line-height;
	color: $heading-font-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	&:last-child {
		margin-bottom: 0;
	}
}

// Heading individual styles
h1 {
	font-size: $h1-mobile;
}

h2 {
	font-size: $h2-mobile;
}

h3 {
	font-size: $h3-mobile;
}

h4 {
	font-size: $h4-mobile;
}

h5 {
	font-size: $h5-mobile;
}

@include small-breakpoint {
	// Heading desktop individual styles
	h1 {
		font-size: $h1;
	}

	h2 {
		font-size: $h2;
	}

	h3 {
		font-size: $h3;
	}

	h4 {
		font-size: $h4;
	}

	h5 {
		font-size: $h5;
	}
}

// Link styling
a {
	color: $link-color;
	text-decoration: none;

	&:hover,
	&:active,
	&:focus {
		color: $link-hover-color;
		text-decoration: underline;
	}
}

// Highlight
mark {
	background: $highlight;
	padding: 0 0.2rem;
}

// Blockquote
blockquote {
	margin: 0 0 $margins 0;
	border-left: $bq-border;
	padding: 0 1.5rem;
	font-size: 1.5rem;

	cite {
		display: block;
		margin-top: $margins;
		font-size: 1rem;
		text-align: right;
	}
}

// Code block styling
pre {
	border: 0;
	border-radius: $border-radius;
	background: $code-background;
	padding: 1rem;
	tab-size: 2;
	color: $code-color;
	font-family: $code-family;
	font-size: $code-size;
	margin: 0 0 $margins 0;

	code {
		font-family: $code-family;
		line-height: 1.2;
	}
}

// Keyboard input
kbd {
	background-color: #f7f7f7;
	border: 1px solid #ccc;
	border-radius: 3px;
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
	color: #333;
	display: inline-block;
	font-family: Helvetica, Arial, sans-serif;
	font-size: 13px;
	line-height: 1.4;
	margin: 0 0.1em;
	padding: 0.1em 0.6em;
	text-shadow: 0 1px 0 #fff;
}

// Inline code styling
:not(pre) > code {
	color: $code-color;
	background: $code-background;
	font-family: $code-family;
	font-size: $code-size;
	padding: 0 0.2rem;
	border: $borders;
	border-radius: $border-radius;
}

// Line break
hr {
	height: 0;
	border: 0;
	border-style: $border-style;
	border-color: $border-color;
	margin: 0;
}

// Definition list
dt {
	font-weight: 600;
}

dd {
	margin-bottom: 0.5rem;
}
