//
// TEXT
//
.text-primary {
	color: $primary-color;
}
.text-white {
	color: white;
}

.button-round-button {
	@extend .button;
	@extend .round-button;
}

.button-muted-button-round-button {
	@extend .button;
	@extend .muted-button;
	@extend .round-button;
}

.button-accent-button-round-button {
	@extend .button;
	@extend .accent-button;
	@extend .round-button;
}
