// Variabili personalizzate

$alternate-background	: #e0e0e0;
$rosso : #ed1c24;
$blu-scuro : #222d35;
$grigio-chiaro: #f9f9f9;
$grigio-scuro: #e1e1e1;
$blu-nero: #161e23;
$bianco: #ffffff;

$x-small                  : 600px                                      !default;
$small                    : 768px                                      !default;
$medium                   : 1024px                                     !default;
$large                    : 1200px                                     !default;

$primary-color            : #ed1c24                                    !default;
$secondary-color          : #29de7d                                    !default;
$alternate-background     : #fafafa                                    !default;

$background-colors: (
	"primary": $primary-color,
	"secondary": $secondary-color,
	"bianco": $bianco,
    "alternate": $alternate-background,
    "grigio-chiaro":  $grigio-chiaro,
    "grigio-scuro": $grigio-scuro,
    "blu-scuro": $blu-scuro,
    "blu-nero": $blu-nero,
    "rosso": $rosso,
) !default;